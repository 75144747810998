<template>
    <c-panel class="spacing-none">
        <c-box class="banner">
            <video
                autoplay
                muted
                loop
                poster="https://firebasestorage.googleapis.com/v0/b/coho-prints.appspot.com/o/website%2FBannerImage.jpg?alt=media&token=ee80c016-19f5-4d26-a8a5-141cff777cc5"
            >
                <source
                    src="https://firebasestorage.googleapis.com/v0/b/coho-prints.appspot.com/o/website%2FBanner.mp4?alt=media&token=79628e16-4fcb-4c43-b27a-fcb9207ec9c2"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
            <div class="overlay"></div>
            <div class="banner-box">
                <div>
                    <h2 class="text-bold mb-1">Fine Art Print Studio</h2>
                    <p class="text-sm text-light">Made in Sammamish, WA</p>
                    <c-button class="large mt-3" @click="$router.push('/customize')">Order a Custom Print</c-button>
                    <c-button class="large mt-2" @click="$router.push('/services')">Fine Art Services</c-button>
                </div>
            </div>
        </c-box>
    </c-panel>
    <c-panel class="spacing-sm">
        <c-grid class="widths-50-sm widths-33-md widths-33-lg widths-33-xl">
            <template v-for="product in products" :key="product.id">
                <c-grid-item
                    v-if="
                        product?.data?.images &&
                        product?.data?.images.length > 0 &&
                        product?.data.category == 'Custom Print'
                    "
                    class="flex f-align-stretch"
                >
                    <c-card @click="$router.push('/product/' + product.id)">
                        <template #image>
                            <c-image :image="product.data.images[0]" />
                        </template>
                        <template #title>{{ pluralize(product.data.name) }}</template>
                        <template #body>{{ product.data.caption }}</template>
                    </c-card>
                </c-grid-item>
            </template>
            <c-grid-item>
                <c-card @click="$router.push('/services')">
                    <template #image>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/coho-prints.appspot.com/o/website%2FServices.jpg?alt=media&token=4a6aeab6-3b36-4782-9b51-3be871d01182"
                        />
                    </template>
                    <template #title>Services</template>
                    <template #body>
                        Artwork digitization, photo restoration, and other services for artists and photographers
                    </template>
                </c-card>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel class="white">
        <c-grid class="widths-50-all widths-100-sm spacing-lg-all">
            <c-grid-item>
                <h3>Personalized Service</h3>
                <p>
                    My name is Daniel, a real person who wants to bring out the best in your images. As a one-man print
                    lab, I give your images the attention they deserve. Don't be suprised if I reach out to you with
                    some questions or recommendations! Your happiness is my goal.
                </p>
            </c-grid-item>
            <c-grid-item>
                <h3>Gallery Quality</h3>
                <p>
                    You won't find prints like these at the drugstore photo lab. The colors are accurate and vibrant,
                    the papers and canvases are delicately textured, and the image quality is supurb. Rest assured that
                    your prints will be produced using the finest materials and techniques available.
                </p>
            </c-grid-item>
            <c-grid-item>
                <h3>Made To Last</h3>
                <p>
                    Images can carry such significance, particularly those we decide to print. That's why I believe
                    prints should be made to last a lifetime. I use only archival materials like pure cotton papers and
                    pigment inks that are rated to last over 100 years without any noticeable fade or color shift.
                </p>
            </c-grid-item>
            <c-grid-item>
                <h3>Attention To Detail</h3>
                <p>
                    Every image I process is meticulously prepared for print to ensure accurate color reproduction and
                    the highest possible detail. No need to worry about pixelated enlargements or suprise sensor spots.
                    If I catch something that seems off, I'll let you know and make the appropriate fixes.
                </p>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel>
        <c-feed category="Home" />
    </c-panel>
    <c-panel class="white">
        <c-grid class="widths-50-all widths-100-sm spacing-lg-all">
            <c-grid-item class="review">
                <h1>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </h1>
                <p>
                    I'm very pleased with the quality of the service received by Daniel. He treated my print as if it
                    was his own. It was very carefully packaged to avoid damage during shipping, and it arrived at my
                    front door promptly.
                </p>
            </c-grid-item>
            <c-grid-item class="review">
                <h1>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </h1>
                <p>
                    Daniel has digitized and printed many paintings for me. He uses a beautiful textured paper, making
                    it difficult to distinguish the original from the print. The colors are exquisite! Professionalism,
                    customer service and quality are the keystones of Coho Prints.
                </p>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel>
        <h1 id="about">About the Printmaker</h1>
        <c-grid class="spacing-lg-all f-justify-center">
            <c-grid-item class="width-100-md width-70-lg width-80-xl">
                <p>
                    Hi, I'm Daniel Fairweather and my passion is bringing images to life. Images weren't meant to be
                    trapped behind a screen, with limited surface area and unnatural lighting. As a printmaker, my wish
                    is to help you discover that magical feeling when the scene you captured comes to life and the
                    experience of capturing it can be re-lived.
                </p>
                <p>
                    As a photographer myself, I know how much the images we capture mean to us. I treat each as if it
                    were my own, putting in the time and attention to detail they deserve so that they can shine in the
                    best possible light.
                </p>
                <p>
                    When I'm not in the studio printing, you can find me hiking and camping all around the Pacific
                    Northwest, or resting my legs at home with my wife Ying and cat Yoshi.
                </p>
            </c-grid-item>
            <c-grid-item class="width-75-xs width-50-md width-30-lg width-20-xl">
                <c-box>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/coho-prints.appspot.com/o/website%2FProfilePic.jpg?alt=media&token=1675f09a-0670-40af-86ea-cca541716249"
                    />
                </c-box>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, watch } from "vue";
import { Database, Analytics } from "vuebase";
import pluralize from "pluralize";
import { Product } from "../main";

export default {
    name: "Home",
    setup() {
        const database = inject(Database.InjectionKey) as Database.Database;
        // const analytics = inject(Analytics.InjectionKey);
        const products = database?.collection<Product>("products").documents();

        return {
            products,
            pluralize,
        };
    },
};
</script>

<style lang="scss">
@import "~coho-ui/src/styles/variables.scss";
@import "~coho-ui/src/styles/mixins.scss";

.review {
    text-align: center;

    h1 {
        color: rgb(241, 188, 42);
        margin-bottom: 12px;
    }
}

.banner {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    video {
        width: 100%;
        height: auto;
        display: block;
        max-height: 300px;
        object-fit: cover;
    }

    .overlay {
        background-color: rgba($color-primary, 0.01);
        backdrop-filter: hue-rotate(-8deg);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .banner-box {
        background-color: rgba($color-white, 0.7);
        backdrop-filter: blur(2px) brightness(140%);
        border-radius: $border-radius;
        box-shadow: 0 3px 7px rgba(black, 0.5);
        padding: $padding-lg;
        position: absolute;
        right: 5%;
        text-align: center;

        @include screen(md) {
            .button:nth-of-type(2) {
                display: none !important;
                color: red !important;
            }
        }

        @include screen(sm) {
            padding: $padding-md;
            h2 {
                font-size: 16px;
            }
            .button {
                font-size: 13px;
            }
            p {
                display: none;
            }
        }

        @include screen(xs) {
            padding: $padding-sm;
        }
    }
}
</style>
