
import { inject, watch } from "vue";
import { Database, Analytics } from "vuebase";
import pluralize from "pluralize";
import { Product } from "../main";

export default {
    name: "Home",
    setup() {
        const database = inject(Database.InjectionKey) as Database.Database;
        // const analytics = inject(Analytics.InjectionKey);
        const products = database?.collection<Product>("products").documents();

        return {
            products,
            pluralize,
        };
    },
};
